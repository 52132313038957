const data = [
  {
    title: "Statement to Christian Science Monitor on security in the Balkans",
    imageUrl: "https://i.imgur.com/1qzZYAv.jpg",
    documentUrl:
      "https://www.csmonitor.com/World/Europe/2017/0421/In-Balkans-a-fragile-order-grows-brittle-threatening-stability",
    date: "28 April 2017",
  },
  {
    title: "Presenting opinion poll on Serbian foreign policy (in Serbian)",
    imageUrl: "https://i.imgur.com/nkAhGCE.jpg",
    documentUrl:
      "http://bezbednost.org/Sve-publikacije/6483/Stavovi-gradjana-o-spoljnoj-politici-Srbije.shtml",
    date: "8 March 2017",
  },
  {
    title: "Presenting opinion poll on Serbian national security (in Serbian)",
    imageUrl: "https://i.imgur.com/tjjWz9k.jpg",
    documentUrl:
      "http://rs.n1info.com/a228078/Vesti/Vesti/BCBP-Najveca-pretnja-korupcija-politicari-i-losa-vlast.html",
    date: "14 February 2017",
  },
  {
    title: "Statement to Radio Free Europe on ISIS recruitment (in Serbian)",
    imageUrl: "https://i.imgur.com/UWX7MWV.jpg",
    documentUrl:
      "https://www.slobodnaevropa.org/a/ko-u-srbiji-vrbuje-borce-za-strana-ratista/27912293.html",
    date: "10 August 2016",
  },
  {
    title: "Interview to B92 on Brussels attack",
    imageUrl: "https://i.imgur.com/wk2NxCx.jpg",
    documentUrl:
      "https://www.b92.net/info/vesti/index.php?yyyy=2016&mm=03&dd=22&nav_category=78&nav_id=1110581",
    date: "22 March 2017",
  },
]

export default data
