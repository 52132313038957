import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MediaItems from "../components/MediaItems"

export default function Media({ location }) {
  return (
    <Layout>
      <SEO title="Media" pagePath={location.pathname} />
      <MediaItems />
    </Layout>
  )
}
