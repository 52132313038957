const data = [
  {
    title: "Interview to N1 (CNN affiliate) on public opinion poll",
    imageUrl: "https://i.imgur.com/djcqVtl.jpg",
    documentUrl:
      "http://rs.n1info.com/a245560/Vesti/Vesti/Istrazivanje-Srbi-okruzenje-vide-kao-neprijateljsko.html",
    date: "29 April 2017",
  },
  {
    title:
      "Interview to N1 (CNN affiliate) on terrorism in Europe (in Serbian)",
    imageUrl: "https://i.imgur.com/HEJeBTV.jpg",
    documentUrl: "https://www.youtube.com/watch?v=IBMrv5WxLo4",
    date: "22 April 2017",
  },
  {
    title:
      "Interview to Al Jazeera Balkans on Paris attack and French elections (in Serbian)",
    imageUrl: "https://i.imgur.com/2mD097P.jpg",
    documentUrl: "https://www.youtube.com/watch?v=DN_-_qULlzA",
    date: "21 April 2017",
  },
  {
    title:
      "Interview to Al Jazeera Balkans on terrorism in Europe (in Serbian)",
    imageUrl: "https://i.imgur.com/zJ8VGq3.jpg",
    documentUrl: "https://www.youtube.com/watch?v=o_2qLe5f6wc",
    date: "26 July 2016",
  },
  {
    title:
      "Talk-show on Serbian National Television (RTS) regarding French measures against terrorism and refugee crisis (in Serbian, part 1)",
    imageUrl: "https://i.imgur.com/iN0qqau.jpg",
    documentUrl: "https://www.youtube.com/watch?v=B0p5ssum3mw",
    date: "22 March 2016",
  },
  {
    title:
      "Talk-show on Serbian National Television (RTS) regarding French measures against terrorism and refugee crisis (in Serbian, part 2)",
    imageUrl: "https://i.imgur.com/5FhSaMX.jpg",
    documentUrl: "https://www.youtube.com/watch?v=sVKg0tIFm18",
    date: "17 November 2015",
  },
  {
    title:
      "Interview to Al Jazeera Balkans on Paris Attacks, terrorism, Syria (in Serbian)",
    imageUrl: "https://i.imgur.com/p4EMuor.jpg",
    documentUrl: "https://www.youtube.com/watch?v=vIC-HvSOvmQ",
    date: "15 November 2015",
  },
  {
    title:
      "Interview to N1 (CNN affiliate) on Serbian foreign policy (in Serbian)",
    imageUrl: "https://i.imgur.com/fsCvL3B.jpg",
    documentUrl:
      "http://rs.n1info.com/a234111/Vesti/Vesti/Stavovi-gradjana-o-Rusiji-NATO-EU.html",
    date: "11 March 2017",
  },
  {
    title: "Interview to N1 (CNN affiliate) on foreign fighters (in Serbian)",
    imageUrl: "https://i.imgur.com/p7puQpU.jpg",
    documentUrl:
      "http://rs.n1info.com/a208754/Vesti/Vesti/Popovic-Problem-su-ljudi-koji-regrutuju-borce-za-Siriju.html",
    date: "18 November 2016",
  },
]

export default data
