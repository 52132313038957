import React, { useState } from "react"
import ContentCard from "./contentCard"
import televisionData from "../data/television"
import radioData from "../data/radio"
import newspaperData from "../data/newspaper"
import styles from "../styles/media.module.scss"

export default function MediaItems() {
  const [mediaSource, setMediaSource] = useState("Television")
  const [mediaData, setMediaData] = useState(televisionData)

  const changeMedia = (mediaSource, data) => {
    setMediaSource(mediaSource)
    setMediaData(data)
  }

  return (
    <div className={styles.mediaContainer}>
      <div className={styles.filtersContainer}>
        <div className={styles.filters}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className={styles.filterIcon}
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M6.17 18a3.001 3.001 0 0 1 5.66 0H22v2H11.83a3.001 3.001 0 0 1-5.66 0H2v-2h4.17zm6-7a3.001 3.001 0 0 1 5.66 0H22v2h-4.17a3.001 3.001 0 0 1-5.66 0H2v-2h10.17zm-6-7a3.001 3.001 0 0 1 5.66 0H22v2H11.83a3.001 3.001 0 0 1-5.66 0H2V4h4.17zM9 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm6 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-6 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
          </svg>
          <button
            className={`${styles.filterButton} ${
              mediaSource === "Television" ? styles.activeFilter : ""
            }`}
            onClick={() => changeMedia("Television", televisionData)}
          >
            Television{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className={styles.icon}
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M2 4c0-.552.455-1 .992-1h18.016c.548 0 .992.445.992 1v14c0 .552-.455 1-.992 1H2.992A.994.994 0 0 1 2 18V4zm2 1v12h16V5H4zm1 15h14v2H5v-2z" />
            </svg>
          </button>
          <button
            className={`${styles.filterButton} ${
              mediaSource === "Radio" ? styles.activeFilter : ""
            }`}
            onClick={() => changeMedia("Radio", radioData)}
          >
            Radio{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className={styles.icon}
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M17 10V8h-2v2H5V6h14v4h-2zM6 3V1h2v2h13.008c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3H6zM4 5v14h16V5H4zm4 13a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
            </svg>
          </button>
          <button
            className={`${styles.filterButton} ${
              mediaSource === "Newspaper" ? styles.activeFilter : ""
            }`}
            onClick={() => changeMedia("Newspaper", newspaperData)}
          >
            Newspaper{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className={styles.icon}
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M16 20V4H4v15a1 1 0 0 0 1 1h11zm3 2H5a3 3 0 0 1-3-3V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v7h4v9a3 3 0 0 1-3 3zm-1-10v7a1 1 0 0 0 2 0v-7h-2zM6 6h6v6H6V6zm2 2v2h2V8H8zm-2 5h8v2H6v-2zm0 3h8v2H6v-2z" />
            </svg>
          </button>
        </div>
        <h1>{mediaSource}</h1>
      </div>

      <div className={styles.mediaItems}>
        {mediaData &&
          mediaData.map((mediaItem, index) => {
            let isYoutube = false
            let itemUrl = mediaItem.documentUrl

            if (mediaItem.documentUrl.includes("youtube")) {
              isYoutube = true
            }

            return (
              <ContentCard
                key={mediaItem.title}
                slug={itemUrl}
                title={mediaItem.title}
                image={mediaItem.imageUrl}
                buttonText="Go To Interview"
                buttonUrl={itemUrl}
                isInternal={false}
                date={mediaItem.date}
                type={isYoutube ? "youtube" : "external"}
                index={index}
              />
            )
          })}
      </div>
    </div>
  )
}
