const data = [
  {
    title:
      "Interview to Radio Belgrade regarding NATO summit, terrorism, N. Korea & Iraq (in Serbian)",
    imageUrl: "https://i.imgur.com/HNAkvBP.jpg",
    documentUrl:
      "http://www.rts.rs/page/radio/sr/story/23/radio-beograd-1/2750599/novinarenje.html",
    date: "29 May 2017",
  },
  {
    title: "Interview to ARD Presse on Serbian Presidential Elections",
    imageUrl: "https://i.imgur.com/RTwjEL5.jpg",
    documentUrl:
      "https://www.ard-wien.de/2017/03/31/wahlkampf-endspurt-fuer-das-praesidentenamt-in-serbien/",
    date: "31 March 2017",
  },
  {
    title:
      "Interview to Radio Belgrade regarding terrorist attacks in Israel and Turkey (in Serbian)",
    imageUrl: "https://i.imgur.com/9MyLXzG.jpg",
    documentUrl:
      "http://www.rts.rs/page/radio/sr/story/23/radio-beograd-1/2588089/.html",
    date: "9 January 2017",
  },
  {
    title: "Radio Bar (Montenegro) on terrorism (in Serbian)",
    imageUrl: "https://i.imgur.com/WSjpAEo.jpg",
    documentUrl: "https://www.youtube.com/watch?v=phYmUAhMHGQ",
    date: "28 November 2016",
  },
  {
    title: "Interview to Radio Sputnik regarding terrorism (in Serbian)",
    imageUrl: "https://i.imgur.com/k42mnfO.jpg",
    documentUrl: "https://clyp.it/f51lf5si",
    date: "20 July 2016",
  },
  {
    title:
      "Interview to Radio Belgrade regarding failed Turkey coup and Nice attack (in Serbian)",
    imageUrl: "https://i.imgur.com/CvBPuUZ.jpg",
    documentUrl:
      "http://www.rts.rs/page/radio/ci/story/27/radio-beograd-1/2389551/novinarenje.html",
    date: "18 July 2016",
  },
  {
    title:
      "Interview to Radio Belgrade regarding response to Brussels attacks (in Serbian)",
    imageUrl: "https://i.imgur.com/0PvEEmX.jpg",
    documentUrl:
      "http://www.rts.rs/page/radio/ci/story/27/%D0%A0%D0%B0%D0%B4%D0%B8%D0%BE+%D0%91%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D0%B4+1/2260086/%D0%A2%D0%B0%D0%BB%D0%B0%D1%81%D0%B0%D1%9A%D0%B5+.html",
    date: "28 March 2016",
  },
  {
    title: "Interview on Hungary and South Stream project (in Serbian)",
    imageUrl: "https://i.imgur.com/hemygvC.jpg",
    documentUrl:
      "http://www.rts.rs/page/radio/ci/story/27/%D0%A0%D0%B0%D0%B4%D0%B8%D0%BE+%D0%91%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D0%B4+1/1743355/%D0%9D%D0%BE%D0%B2%D0%B8%D0%BD%D0%B0%D1%80%D0%B5%D1%9A%D0%B5.html",
    date: "6 November 2014",
  },
  {
    title: "Interview on North Korean nuclear threats (in Serbian)",
    imageUrl: "https://i.imgur.com/YVqbV9n.jpg",
    documentUrl:
      "http://www.rts.rs/page/radio/ci/story/27/%D0%A0%D0%B0%D0%B4%D0%B8%D0%BE+%D0%91%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D0%B4+1/1306078/%D0%9D%D0%BE%D0%B2%D0%B8%D0%BD%D0%B0%D1%80%D0%B5%D1%9A%D0%B5.html",
    date: "16 April 2013",
  },
]

export default data
